<template>
  <div class="history">
    <van-sticky>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false" />
      <div v-if="!$AppData.config.themeType" class="journal-date">
        {{`${startTime} - ${endTime}` }}
        <img class="triangle" src="/images/triangle.png">
      </div>
      <div v-else>
        <div class="journal-date">
          <p class="icon"></p>
          <p class="text">{{$t('field.startTime')}}</p><p class="icon"></p>
          <p class="text">{{$t('field.endTime')}}</p><p class="icon"></p>
        </div>
        <div class="journal-date" @click="showCalendar = true">
          <p class="icon"><van-icon class-prefix="my-icon" name="calendar" size="22" /></p>
          <p class="text">{{startTime}}</p>
          <p class="icon">~</p>
          <p class="text">{{endTime}}</p>
          <p class="icon"><img class="triangle" src="/images/triangle.png"></p>
        </div>
      </div>
    </van-sticky>
    <div class="history-list">
      <van-list v-if="dataList.length >0">
        <van-cell v-for="item in dataList" :key="item.id" :title="item.time" :value="getTargetValue(item)" />
      </van-list>
      <van-empty v-else :description="$t('common.noData')" />
    </div>
    <van-calendar
      v-model:show="showCalendar"
      type="range"
      :color="$primary"
      :allow-same-day="true"
      :min-date="new Date(2020, 0, 1)"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { querySubtotalHistoryList } from "@/api";
  
export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const dataList = ref([]);
    return {
      goBack,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      historyCode: localStorage.getItem('historyCode'),
      startTime: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      endTime: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      showCalendar: false,
    };
  },

  mounted() {
    this.fetchList();
  },
  
  methods: {
    fetchList() {
      querySubtotalHistoryList({
        startTime: this.startTime,
        endTime: this.endTime,
      }).then(res => {
        this.dataList = this.dataList = res.data || [];
      });
    },
    onConfirm(values) {
      const [start, end] = values;
      this.showCalendar = false;
      this.startTime = moment(start).format('YYYY-MM-DD');
      this.endTime = moment(end).format('YYYY-MM-DD');
      this.fetchList();
    },
    getTargetValue(item) {
      let targetValue = '';
      switch (this.historyCode){
        case "NUMBER_OF_USER_REGISTRATIONS"://注册人数
          targetValue = item.registerNum;
          break;
        case "INCOME"://收益
          targetValue = item.orderFeeStr;
          break;
        case "REBATE"://返佣
          targetValue = item.orderIncomeStr;
          break;
        case "VOLUME"://成交量
          targetValue = item.runningVolumeStr;
          break;
        case "PURCHASE_AMOUNT"://购买量
          targetValue = item.rechargeLimitStr;
          break;
        case "SUCCESS_RATE"://成功率
          targetValue = item.orderSuccessRate + "%";
          break;
        case "NUMBER_OF_ORDER"://订单数
          targetValue = item.orderNum;
          break;
        case "BUY_COINS_QUICKLY_VOLUME"://急速跑量
        case "BUY_COINS_QUICKLY_INCOME"://急速收益
          targetValue = item.runningVolumeStr;
          break;
        default:
          break;
      }
      return targetValue;
    },
  },
};
</script>

<style lang="less">
.history {
  .journal-date {
    color: #6F6F93;
    font-size: 14px;
    padding: 10px 15px 10px;
    line-height: 18px;
    background: #f9fafa;
    .triangle {
      width: 8px;
      margin-left: 4px;
      vertical-align: middle;
    }
  }
  .history-list {
    text-align: center;
    .van-cell__value {
      text-align: center;
    }
  }
}

.theme1 {
  .history {
    min-height: 100vh;
    background: #fff;
    .journal-date {
      display: flex;
      font-weight: 700;
      color: #3d3d3d;
      padding: 0 32px;
      line-height: 48px;
      background: @background-color;
      p.icon {
        width: 20px;
        text-align: center;
        span {
          font-size: 20px;
          color: @primary-color;
        }
      }
      p.text {
        flex: 1;
        text-align: center;
      }
    }
    .history-list {
      margin-top: 20px;
      .van-list {
        padding: 0 20px;
        background: @background-color;
      }
      .van-cell {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        font-weight: 500;
        color: #3d3d3d;
        background: transparent;
        border-bottom: 1px solid #a5a5a5;
        &:nth-last-child(2) {
          border-bottom: none;
        }
      }
      .van-cell__value {
        color: #3d3d3d;
        text-align: right;
      }
    }
  }
}
</style>
